import { FetchState } from '@root/common/domain';
import type { FetchState as IFetchState, FetchStateInput } from '@root/common/types/domain';
import type { Data as Issue } from '@root/modules/issue/types/issue';

interface IIssueFetchState extends IFetchState {
  type: 'issue' | 'latestIssue' | 'issueList';
  issue?: Issue | null;
  issueList?: Issue[];
}

export class IssueFetchState extends FetchState implements IIssueFetchState {
  private _issue: IIssueFetchState['issue'];
  private _issueList: IIssueFetchState['issueList'];
  private _type: IIssueFetchState['type'];

  constructor(input: FetchStateInput<IIssueFetchState>) {
    super(input);
    this._issue = input.issue;
    this._issueList = input.issueList;
    this._type = input.type;
  }

  get issue(): IIssueFetchState['issue'] {
    return this._issue;
  }

  set issue(issue: IIssueFetchState['issue']) {
    this._issue = issue;
  }

  get issueList(): IIssueFetchState['issueList'] {
    return this._issueList;
  }

  set issueList(issueList: IIssueFetchState['issueList']) {
    this._issueList = issueList;
  }

  get type(): IIssueFetchState['type'] {
    return this._type;
  }

  set type(type: IIssueFetchState['type']) {
    this._type = type;
  }
}
