type Fn = () => void;
const scrollTo = (elementY: number, duration: number, cb?: Fn) => {
  const startingY = window.pageYOffset;
  const diff = elementY - startingY;
  let start = 0;

  // Bootstrap our animation - it will get called right before next frame shall be rendered
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling
    const time = timestamp - start;
    // Get percent of completion in range [0, 1]
    const percent = Math.min(time / duration, 1);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to
    if (time < duration) {
      window.requestAnimationFrame(step);
    } else if (cb) {
      cb();
    }
  });
};

export default scrollTo;
