
import Vue from 'vue';
import find from 'lodash/find';
import articleConfig from '@root/modules/article/config/article.config';
import { LeadElementAttrs, IssueHeadlinesData } from '@root/modules/issue/types/issue';
import ContentImage from '@core/components/UI/ContentImage.vue';
import ContentFragment from '@core/components/ContentFragment.vue';

interface Props {
  issue: IssueHeadlinesData;
}

interface Computed {
  issueRembiCover?: {
    type: string;
    attrs: LeadElementAttrs;
  };
  picture?: {
    type: string;
    attrs: LeadElementAttrs;
  };
}

const sizeMap: Record<string, Record<string, unknown>> = articleConfig.infoCover;

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    ContentImage,
    ContentFragment,
  },
  props: {
    issue: {
      type: Object,
      required: true,
    },
  },
  computed: {
    issueRembiCover() {
      const rembi = find(this.issue.leadElement?.content, { attrs: { type: 'rembi' } });
      return rembi;
    },
    picture() {
      const picture = find(this.issue.leadElement?.content, { attrs: { type: 'image' } });

      if (picture) {
        picture.attrs.cropperData = {
          ...sizeMap.full,
          ...picture.attrs.cropperData,
        };
      }
      return picture;
    },
  },
});
