import { RootContext } from '@root/common/domain';
import type { RootContextInput } from '@root/common/types/domain';

type IssueExposeContext = 'channel' | 'route';

export class IssueFetchContext extends RootContext<IssueExposeContext> {
  constructor(input: RootContextInput<IssueExposeContext>) {
    super(input);
  }
}
