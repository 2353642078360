import { dataLoader } from '@root/libs/redis/dataloader/dataLoader';
import { QueryOptions } from '@apollo/client/core';

import Service from '@root/common/base/Service';

import { IssueFetchState, IssueFetchContext } from '@root/modules/issue/domain';

import getIssuesWithHeadlines from '@root/modules/issue/graphql/getIssuesWithHeadlines.graphql';

import { getRouteParams } from '@root/common/utils/url';

import type { GetIssues, GetIssuesWithHeadlines } from '@root/modules/issue/types/issue';
import type { ServiceResponse } from '@root/common/types/service';

type IssueWithHeadlinesFetchVariables = GetIssues['variables'];
type IssueWithHeadlinesApiResponse = GetIssuesWithHeadlines['data'];

export class IssueWithHeadlinesService extends Service {
  constructor() {
    super({ serviceType: 'issue' });
  }

  /**
   * Get the service variables required for making a request
   */
  private getServiceVariables(state: IssueFetchState, context: IssueFetchContext): IssueWithHeadlinesFetchVariables {
    const params = getRouteParams({ id: context.route.params.issueid, desiredIdType: 'number' });
    const variables: IssueWithHeadlinesFetchVariables = {
      limit: 1,
    };

    if (state.type === 'issue') {
      variables.id = Number(params.id);
    }

    if (state.type === 'latestIssue') {
      variables.domain = context.channel.settings.domain;
    }

    return variables;
  }

  public async fetch(state: IssueFetchState, context: IssueFetchContext): ServiceResponse<IssueWithHeadlinesApiResponse> {
    const variables = this.getServiceVariables(state, context);

    const options = Object.assign({ query: getIssuesWithHeadlines }, { variables });
    const dataLoaderOptions = {
      remote: {
        keyPrefix: 'single_issue',
      },
    };

    // Create a request wrapper to handle API requests
    const requestWrapper = async (options: QueryOptions): Promise<IssueWithHeadlinesApiResponse | Error> => {
      const apiProvider = this.createProvider('GraphQL');
      apiProvider.selectAPI('content-api-v3').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });
      const response = await apiProvider.query<IssueWithHeadlinesApiResponse>(options);

      this.throwGraphqlOrApolloErrorIfExists(response);

      return response.data;
    };

    const redisDataLoader = dataLoader<QueryOptions, IssueWithHeadlinesApiResponse | Error>(requestWrapper, dataLoaderOptions);

    // Fetch data from redis or API
    const response: IssueWithHeadlinesApiResponse | Error = redisDataLoader
      ? await redisDataLoader.load(options)
      : await this.requestWrapperHandler<IssueWithHeadlinesApiResponse | Error>(() => requestWrapper(options));

    // Handle internal graphql errors
    if (response instanceof Error) {
      const errorData = this.generateErrorData(response);
      return [null, errorData];
    }

    return [response, null];
  }
}
