import { FetchHandler } from '@root/common/domain';

import { IssuesListService, IssueWithHeadlinesService } from '@root/modules/issue/services';
import { IssueFetchDataError } from '@root/modules/issue/errors';

import { isValidSignedInt32 } from '@root/common/utils/helpers';

import { IssueFetchState } from './IssueFetchState.domain';
import { IssueFetchContext } from './IssueFetchContext.domain';

import { FetchStatus, type FetchResponse, type FetchErrorResponse } from '@root/common/types/domain';
import type { Data as Issue, IssueHeadlinesData as Headlines } from '@root/modules/issue/types/issue';
import type { Pager } from '@root/common/types/fragment';

type IssueWithHeadlinesData = {
  issue: Issue;
  headlines: {
    items: Headlines['headlines']['items'][0]['article'][];
  };
};

type IssuesListData = {
  issuesList: Issue[];
  pager: Pager;
};

export class IssueFetchHandler extends FetchHandler<IssueFetchState, IssueFetchContext> {
  constructor(IssueFetchState: IssueFetchState, IssueFetchContext: IssueFetchContext) {
    super(IssueFetchState, IssueFetchContext);
  }

  /**
   * Validate input before fetching data with service and return error if input is invalid
   */
  private handleInputError(idOrSlug: number | string): FetchErrorResponse<IssueFetchDataError> | null {
    let fetchInputError: null | IssueFetchDataError = null;

    // Check if the input is a valid slug used to fetch latest issues
    const isLatestIssue = Number.isNaN(Number(idOrSlug));

    if (isLatestIssue) {
      this.state.type = 'latestIssue';
      return null;
    }

    // No valid slug is provided - check for valid issue id
    const id = Number(idOrSlug);

    if (!isValidSignedInt32(id)) {
      this.state.fetchStatus = FetchStatus.Error;

      fetchInputError = new IssueFetchDataError('Issue fetch error - invalid input', {
        state: this.state,
      });

      fetchInputError.responseCode(400);

      return this.errorResponse(fetchInputError);
    }

    return null;
  }

  /**
   * Fetch issue with headlines
   */
  public async fetchIssueWithHeadlines(issueService: IssueWithHeadlinesService): FetchResponse<IssueWithHeadlinesData, IssueFetchDataError> {
    const inputError = this.handleInputError(this.context.route.params.issueid);

    // Handle invalid input error
    if (inputError) {
      return inputError;
    }

    // Handle data fetching with service
    const [serviceResponse, serviceError] = await issueService.fetch(this.state, this.context);

    // Handle invalid response errors
    const noIssuesOrHeadlines = !serviceResponse?.issues.data.length || !serviceResponse.issues.data[0].headlines.items.length;

    if (serviceError || noIssuesOrHeadlines) {
      this.state.fetchStatus = FetchStatus.Error;

      const fetchDataError = new IssueFetchDataError('Issue fetch error - invalid headlines data', {
        state: this.state,
      });

      fetchDataError.sync(serviceError).responseCode(404, noIssuesOrHeadlines);

      return this.errorResponse(fetchDataError);
    }

    // Handle successful data fetch

    const response = serviceResponse.issues.data[0];
    const { headlines: headlinesResponse, ...issue } = response;
    const headlines = { items: headlinesResponse.items.map((item) => item.article) };

    return this.successResponse({ issue, headlines });
  }

  /**
   * Fetch Issues list
   */
  public async fetchIssuesList(issueService: IssuesListService): FetchResponse<IssuesListData, IssueFetchDataError> {
    // Handle data fetching with service
    const [serviceResponse, serviceError] = await issueService.fetch(this.state, this.context);

    // Handle invalid response errors
    const noIssues = !serviceResponse?.issues.data.length;

    if (serviceError || noIssues) {
      this.state.fetchStatus = FetchStatus.Error;

      const fetchDataError = new IssueFetchDataError('Issues list fetch error - invalid headlines data', {
        state: this.state,
      });

      fetchDataError.sync(serviceError).responseCode(404, noIssues);

      return this.errorResponse(fetchDataError);
    }

    // Handle successful data fetch

    const issuesList = serviceResponse.issues.data;
    const pager = serviceResponse.issues.pager;

    return this.successResponse({ issuesList, pager });
  }
}
