
import Page from '@root/common/base/Page';

import { IssueFetchState, IssueFetchContext, IssueFetchHandler, IssuePath, IssueRedirectStatus } from '@root/modules/issue/domain';
import { IssueWithHeadlinesService } from '@root/modules/issue/services';

import IssueTopRibbon from '@root/modules/issue/components/IssueTopRibbon.vue';
import IssueHeadline from '@root/modules/issue/components/IssueHeadline.vue';
import ArticleItem from '@root/modules/article/components/Article.vue';

import { getIssueHeadlineWithHref } from '@root/modules/headlines/utils/getIssueHeadlineWithHref';
import scrollTo from '@root/common/utils/scrollTo';

import { buildRoutePath } from '@root/router/utils/buildRoutePath';

import type { Data as HeadlinesData, DataWithHref as HeadlinesDataWithHref } from '@root/modules/headlines/types/headlines';
import type { State as ArticleState } from '@root/modules/article/types/article';
import type { Data as IssueData } from '@root/modules/issue/types/issue';

interface Data {
  nextArticleLoading: boolean;
  lastArticleId: string | null;
  expandedArticles: string[];
  showCover: boolean;
}

interface Computed {
  issueData: IssueData | null;
  issueHeadlines: HeadlinesData[];
  activeArticleId: ArticleState['activeArticleId'];
  issueArticles: string[];
  firstArticle: boolean;
  issuePath: string;
}

interface Methods {
  getHeadline: (headline: HeadlinesData) => HeadlinesDataWithHref;
  updateScrollPosition: (articleId: string) => void;
  loadNextArticle: () => void;
  waitForNextArticle: (articleId: string | null) => void;
  setPlayerArticles(): void;
  handleRedirect: (issue: IssueData) => void;
  handleArticleLoad: (isLoaded: boolean) => void;
  updateIssuePath: () => void;
}

export default Page.extend<Data, Methods, Computed, unknown>({
  components: {
    IssueTopRibbon,
    IssueHeadline,
    ArticleItem,
  },
  // Don't add next(), it breaks issue custom navigation
  beforeRouteUpdate() {
    this.lastArticleId = this.activeArticleId;
    this.expandedArticles = this.activeArticleId ? [this.activeArticleId] : [];
    this.waitForNextArticle(this.activeArticleId);
  },
  beforeRouteLeave(to, from, next) {
    // Reset issue data and headlines if user leaves issue route
    if (to.name !== 'issue-article') {
      this.$store.commit('article/setActiveArticleId', null);
      this.$store.commit('issue/setIssueData', null);
      this.$store.commit('issue/setIssueHeadlines', []);
    }
    next();
  },
  layout(context) {
    return typeof context.query.listen === 'undefined' ? 'default' : 'blank';
  },
  data() {
    return {
      nextArticleLoading: false,
      lastArticleId: null,
      expandedArticles: [],
      showCover: true,
    };
  },
  async fetch() {
    // Fetch issue data only if issue store is empty or issue route id doesn't match to issue id in store
    if (this.issueData && this.issueData.id === Number(this.$route.params.issueid)) {
      return;
    }

    const issueFetchState = new IssueFetchState({ refetchType: this.refetch.activeType, issue: this.issueData, type: 'issue' });
    const issueFetchContext = new IssueFetchContext({ channel: this.$channelConfig('config'), route: this.$route });

    const issueFetchHandler = new IssueFetchHandler(issueFetchState, issueFetchContext);

    // Fetch issue list data
    this.startFetching(issueFetchHandler.state.fetchStatus);
    const [issuesResponse, issuesResponseError] = await issueFetchHandler.fetchIssueWithHeadlines(new IssueWithHeadlinesService());

    if (issuesResponseError) {
      // Try 1 refetch before using stored data as fallback
      const willRefetch = this.handleFetchError(issueFetchHandler.state.fetchStatus, issuesResponseError);

      if (willRefetch) {
        return;
      }

      this.handlePageError(issuesResponseError);
      return;
    }

    const settings = this.$channelConfig('page').issue.component.issueCover;
    this.showCover = settings.showCoverInList;

    const { issue, headlines } = issuesResponse;

    this.$store.commit('issue/setIssueData', issue);
    this.handleRedirect(issue);

    if (headlines) {
      this.$store.commit('issue/setIssueHeadlines', headlines.items);
      this.lastArticleId = this.$route.params.id || String(headlines.items[0].id);
      this.$store.commit('article/setActiveArticleId', this.lastArticleId);
      this.expandedArticles.push(this.lastArticleId as string);
    }

    // Handle successful fetch
    this.stopFetching(issueFetchHandler.state.fetchStatus);
  },
  computed: {
    issueData() {
      return this.$store.getters['issue/getIssueData'] || null;
    },
    issueHeadlines() {
      return this.$store.getters['issue/getIssueHeadlines'];
    },
    activeArticleId() {
      return this.$store.getters['article/getActiveArticleId'];
    },
    issueArticles() {
      return this.issueHeadlines.map((headline) => String(headline.id)) || [];
    },
    firstArticle() {
      return this.activeArticleId ? this.issueArticles.indexOf(this.activeArticleId) === 0 : false;
    },
    issuePath() {
      const { domain } = this.$channelConfig('settings');
      const issue = this.$store.getters['issue/getIssueData'];
      const issueId = (issue && issue.id) || this.$route.params.issueid || null;

      if (!issueId) {
        return '';
      }

      const pathOptions = {
        type: 'issue',
        id: issue.id,
        channel: issue.channel,
      };

      return buildRoutePath({ pathOptions, domain });
    },
  },
  watch: {
    issueHeadlines(headlines) {
      if (process.server || !headlines.length) {
        return;
      }

      this.setPlayerArticles();
    },
  },
  mounted() {
    window.addEventListener('scroll', this.loadNextArticle);
  },
  destroyed() {
    window.removeEventListener('scroll', this.loadNextArticle);
  },
  methods: {
    loadNextArticle() {
      if (!this.lastArticleId) {
        return false;
      }

      const $lastOpened = document.getElementById(`article-${this.lastArticleId}`);

      if (!$lastOpened) {
        return false;
      }

      const lastOpenedBottom = $lastOpened!.getBoundingClientRect().bottom;
      const { clientHeight } = document.documentElement;
      const lastOpenedBottomDiff = lastOpenedBottom - clientHeight;
      const userHasScrolled = window.pageYOffset > 200;

      // Remove event listener if user scrolls down too fast
      if (clientHeight + lastOpenedBottomDiff < 0) {
        window.removeEventListener('scroll', this.loadNextArticle);
      }

      // Get next article if last opened article scroll position less than bottom offset
      if (userHasScrolled && lastOpenedBottomDiff < 400 && !this.nextArticleLoading) {
        const lastArticleIndex = this.issueArticles.indexOf(this.lastArticleId);
        this.nextArticleLoading = true;

        if (lastArticleIndex < 0) {
          return false;
        }

        const nextArticle = this.issueArticles[lastArticleIndex + 1];

        if (!nextArticle) {
          return;
        }

        // Max number of expanded articles while scrolling
        if (this.expandedArticles.length >= 4) {
          this.expandedArticles.shift();
        }

        this.lastArticleId = nextArticle;
        this.expandedArticles.push(nextArticle);
        this.$store.commit('article/setActiveArticleId', nextArticle);
      }
    },
    getHeadline(headline) {
      const data: any = {
        issue: this.issueData,
        locale: this.$channelConfig('settings').locale,
        domain: this.$channelConfig('settings').domain,
        headline,
        fakeDomainEnabled: this.$config.FAKE_DOMAIN_ENABLED,
      };
      return getIssueHeadlineWithHref(data);
    },
    updateScrollPosition(articleId) {
      // Set window scroll position to opened article
      const $articleElement = document.getElementById(`issue-headline-${articleId}`);
      const $header = document.querySelector('.header');
      const $siteHeader = document.getElementById('delfi-adx-siteheader');
      const headerHeight = $header ? $header.clientHeight : 0;
      const siteHeaderHeight = $siteHeader ? $siteHeader.clientHeight : 0;

      if ($articleElement) {
        const articleElementY = $articleElement.getBoundingClientRect().top;
        const topPosition = this.firstArticle ? 1 : window.pageYOffset + articleElementY - headerHeight - siteHeaderHeight;
        scrollTo(topPosition, 300, () => window.addEventListener('scroll', this.loadNextArticle));
      }
    },
    updateIssuePath() {
      const domain = this.$channelConfig('settings').domain;
      const article = this.$store.getters['article/getActiveArticle'];

      if (!article) {
        return;
      }

      const pathOptions = {
        type: 'article',
        id: article.id,
        slug: article.slug,
        channel: article.primaryCategory.channel,
      };

      const issuePath = `${this.issuePath}${buildRoutePath({ pathOptions, domain })}`;

      if (window.location.pathname !== issuePath) {
        window.history.replaceState({}, '', issuePath);
      }
    },
    waitForNextArticle(articleId) {
      if (!articleId) {
        return;
      }

      // Remove temporary listener only on direct click to prevent wrong scrolling position
      if (this.expandedArticles.length === 1 && process.client) {
        window.removeEventListener('scroll', this.loadNextArticle);
      }

      if (!this.lastArticleId) {
        this.lastArticleId = articleId;
      }

      this.nextArticleLoading = false;

      this.$nextTick(() => {
        this.updateScrollPosition(articleId);
      });
    },
    handleArticleLoad(isLoaded) {
      this.nextArticleLoading = !isLoaded;

      if (isLoaded) {
        this.updateIssuePath();
      }
    },
    setPlayerArticles() {
      this.$ttsPlayer.loadArticlesByIds(this.issueHeadlines.map((headline) => headline.id));
    },
    handleRedirect(issue: IssueData) {
      const issuePath = new IssuePath(issue);

      if (!issuePath.getState('isDataValid')) {
        this.$sentry.captureException(issuePath.getState('error'), {
          tags: { 'process.type': process.server ? 'server' : 'client' },
        });
        return;
      }

      const redirectPath = issuePath.createPath({
        domain: this.$channelConfig('settings').domain,
        fakeDomainEnabled: this.$config.FAKE_DOMAIN_ENABLED,
        query: this.$nuxt.context.query,
        route: this.$nuxt.context.route,
      });

      if (issuePath.getState('redirectStatus') === IssueRedirectStatus.Error) {
        this.$sentry.captureException(issuePath.getState('error'), {
          tags: { 'process.type': process.server ? 'server' : 'client' },
        });
        return;
      }

      if (issuePath.getState('redirectStatus') === IssueRedirectStatus.Redirect) {
        this.handlePageRedirect(redirectPath);
      }
    },
  },
});
