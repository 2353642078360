import Service from '@root/common/base/Service';
import { QueryOptions } from '@apollo/client/core';

import { IssueFetchState, IssueFetchContext } from '@root/modules/issue/domain';

import getIssues from '@root/modules/issue/graphql/getIssues.graphql';

import { getPageOffset } from '@root/common/utils/getPageOffset';
import { dataLoader, RedisDataLoaderOptions } from '@root/libs/redis/dataloader/dataLoader';

import type { GetIssues } from '@root/modules/issue/types/issue';
import type { ServiceResponse } from '@root/common/types/service';

type IssuesListFetchVariables = GetIssues['variables'];
type IssuesListApiResponse = GetIssues['data'];

export class IssuesListService extends Service {
  constructor() {
    super({ serviceType: 'issues' });
  }

  /**
   * Get the service variables required for making a request
   */
  private getServiceVariables(state: IssueFetchState, context: IssueFetchContext): IssuesListFetchVariables {
    const limit = 40;
    const offset = getPageOffset(limit, context.route.query.page as string);
    const variables: GetIssues['variables'] = { domain: context.channel.settings.domain, offset, limit };

    return variables;
  }

  public async fetch(state: IssueFetchState, context: IssueFetchContext): ServiceResponse<IssuesListApiResponse> {
    const variables = this.getServiceVariables(state, context);

    const options = Object.assign({ query: getIssues }, { variables });
    const dataLoaderOptions: RedisDataLoaderOptions = {
      remote: {
        keyPrefix: 'issues',
      },
    };

    // Create a request wrapper to handle API requests
    const requestWrapper = async (options: QueryOptions): Promise<GetIssues['data'] | Error> => {
      const apiProvider = this.createProvider('GraphQL');
      apiProvider.selectAPI('content-api-v3').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });
      const response = await apiProvider.query<GetIssues['data']>(options);

      this.throwGraphqlOrApolloErrorIfExists(response);

      return response.data;
    };

    if (variables.offset !== 0) {
      dataLoaderOptions.remote!.gracePeriodMs = 600000; // 10 minutes for pager
    }

    const redisDataLoader = dataLoader<QueryOptions, GetIssues['data'] | Error>(requestWrapper, dataLoaderOptions);

    // Fetch data from redis or API
    const response: IssuesListApiResponse | Error = redisDataLoader
      ? await redisDataLoader.load(options)
      : await this.requestWrapperHandler<IssuesListApiResponse | Error>(() => requestWrapper(options));

    // Handle internal graphql errors
    if (response instanceof Error) {
      const errorData = this.generateErrorData(response);
      return [null, errorData];
    }

    return [response, null];
  }
}
