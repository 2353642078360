import { Data as HeadlinesData, DataWithHref } from '@root/modules/headlines/types/headlines';
import { Data as IssueData } from '@root/modules/issue/types/issue';
import { buildRoutePath, buildRelativeRoute } from '@root/router/utils/buildRoutePath';

type Arguments = {
  issue: IssueData;
  domain: string;
  locale: string;
  headline: HeadlinesData;
  fakeDomainEnabled: boolean;
};

export function getIssueHeadlineWithHref(data: Arguments): DataWithHref {
  const { issue, domain, fakeDomainEnabled } = data;
  const headline = { ...data.headline };

  const issuePathOptions = {
    type: 'issue',
    id: issue.id,
    channel: issue.channel,
  };

  const hrefIssue = buildRoutePath({ pathOptions: issuePathOptions, domain, fakeDomainEnabled });

  const pathOptions = {
    type: 'article',
    id: headline.id,
    slug: headline.slug,
    channel: headline.primaryCategory.channel,
  };
  const hrefArticle = buildRelativeRoute({ pathOptions });
  const href = `${hrefIssue}${hrefArticle}`;
  const headlineWithHref = { ...headline, href };
  return headlineWithHref;
}
