import { SentryError, type ErrorContext, type ErrorTags, type ServiceErrorTags } from '@root/common/base/SentryError';
import { IssueFetchState } from '@root/modules/issue/domain';

import { IssueFetchErrorReason } from './IssueFetchReason.error';

interface issueFetchErrorContext {
  cause: {
    reason: IssueFetchErrorReason.NoData;
  };
  data: {
    type: IssueFetchState['type'];
    issue: IssueFetchState['issue'];
    issuesList: IssueFetchState['issueList'];
    refetchType: IssueFetchState['refetchType'];
  };
}

export class IssueFetchDataError extends SentryError<issueFetchErrorContext, ServiceErrorTags> {
  constructor(message: string, { state }: { state: IssueFetchState }) {
    super(message);
    this.clientMessage = state.type === 'issue' || state.type === 'latestIssue' ? 'issue.error.not_found' : 'issues.error.not_found';

    const sentryContext: ErrorContext<issueFetchErrorContext> = {
      cause: {
        reason: IssueFetchErrorReason.NoData,
      },
      data: {
        type: state.type,
        issue: state.issue,
        issuesList: state.issueList,
        refetchType: state.refetchType,
      },
    };

    const sentryTags: ErrorTags<ServiceErrorTags> = {
      apiType: 'content',
      responseCode: 500,
    };

    this.setInitialContexts(sentryContext);
    this.setInitialTags(sentryTags);
  }
}
