import { PagePathHandler, RedirectStatus, RedirectConditions } from '@root/common/base/PagePathHandler';
import matchChannel from '@root/common/utils/matchChannel';
import { buildRoutePath, buildQueryUrl } from '@root/router/utils/buildRoutePath';
import { Data as IssueData } from '@root/modules/issue/types/issue';
import { Context } from '@nuxt/types';

export { RedirectStatus as IssueRedirectStatus };

interface IssuePathContext {
  domain: string;
  fakeDomainEnabled: boolean;
  query: Context['route']['query'];
  route: Context['route'];
}

type IssueDataDeconstructed = Pick<IssueData, 'id' | 'channel'>;

/**
 * Create issue path for redirecting to the correct channel
 */
export class IssuePath extends PagePathHandler {
  private _issuePathData: IssueDataDeconstructed | null = null;

  constructor({ id, channel }: IssueDataDeconstructed, options?: { redirectConditions: Partial<RedirectConditions> }) {
    super({
      name: 'IssuePath',
      redirectConditions: {
        channel: true,
        id: true,
        slug: false,
        ...options?.redirectConditions,
      },
    });

    this.initializeAndValidate({ id, channel });
  }

  /**
   * Initialize and validate the issue data
   * Check if the issue data is valid and set the error if not
   */
  private initializeAndValidate({ id, channel }: IssueDataDeconstructed): void {
    // TODO: .? operator should be removed after the issue data types are fixed
    const domain = channel?.domain;

    const isDataValid = Boolean(typeof id === 'number' && channel && domain);

    this._isDataValid = isDataValid;
    this._issuePathData = isDataValid ? { id, channel } : null;

    if (!this._isDataValid) {
      this._error = new Error(`${this._name} initializeAndValidate: Invalid issue data - id: ${id}, channel: ${JSON.stringify(channel)}`);
    }
  }

  /**
   * Create new path for the issue redirect or return null if no redirect is needed
   */
  public createPath({ domain, fakeDomainEnabled, query, route }: IssuePathContext): string | null {
    // Handle invalid data
    if (!this.isRedirectDataValid(this._issuePathData)) {
      return null;
    }

    const { id, channel } = this._issuePathData;
    const pathOptions = { type: 'issue', id, channel };
    const issueDomain = channel.domain;

    const sameChannel = matchChannel(domain, issueDomain);

    // Check redirect conditions
    if (!this.shouldRedirect({ id, sameChannel, route })) {
      return null;
    }

    // Build the URL for redirect
    const url = buildRoutePath({ pathOptions, domain, fakeDomainEnabled });
    const queryUrl = buildQueryUrl(url, { query, route }) || null;

    // Handle invalid URL data
    // Skip redirect if the current URL is the same as the new URL - prevent infinite loop
    if (!this.isRedirectUrlValid(queryUrl, { domain, route })) {
      return null;
    }

    // Create the new path for redirection
    return this.handleRedirectPath(queryUrl);
  }
}
