
import Vue from 'vue';
import { DataWithHref } from '@root/modules/headlines/types/headlines';
import ContentImage from '@core/components/UI/ContentImage.vue';
import getItemFromObject from 'lodash/get';

interface Props {
  headline: DataWithHref;
  pictureSrcset: Record<string, unknown>;
  hideButton: boolean;
  inSidebar: boolean;
}

interface Methods {
  openArticle: (href: string, articleId: number) => void;
  getItemFromObject: typeof getItemFromObject;
}

interface Computed {
  isHeadlineActive: boolean;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    ContentImage,
  },
  props: {
    headline: {
      type: Object,
      required: true,
    },
    hideButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    inSidebar: {
      type: Boolean,
      required: false,
      default: false,
    },
    pictureSrcset: {
      type: Object,
      default: () => null,
      required: false,
    },
  },
  computed: {
    isHeadlineActive() {
      return String(this.headline.id) === String(this.$store.getters['article/getActiveArticleId']);
    },
  },
  methods: {
    openArticle(href, articleId) {
      this.$store.commit('article/setActiveArticleId', String(articleId));
      this.$store.commit('navigation/setHeaderAction', { type: 'issueList', value: false });

      // Handle navigation error with page reload
      // Error happens when user pushes first opened article route to router
      this.$router.push({ path: href }).catch(() => {
        document.location.reload();
      });
    },
    getItemFromObject,
  },
});
